import React from 'react';
import BaseTemplate from '../../components/templates/BaseLayout';
import SEO from '../../components/atoms/utility/seo';
import Spacer from '../../components/atoms/utility/Spacer';
import EventsListBlogArtPrize from '../../components/organisms/EventsListBlogArtPrize';
import { austinEvents } from '../../content/events/austin-events';
import useFetchArtPrizeActivities from '../../utils/hooks/useFetchArtPrizeActivities';

function ArtPrize() {
  const { featureCollection } = useFetchArtPrizeActivities();

  return (
    <BaseTemplate>
      <SEO title="ArtPrize" description="Come find events at ArtPrize." />
      <Spacer sizeY={1} className="" />
      <EventsListBlogArtPrize eventsData={featureCollection} />
      <Spacer sizeY={1} className="" />
    </BaseTemplate>
  );
}

export default ArtPrize;
