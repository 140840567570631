import React from 'react';
import Paragraph from '../atoms/typography/Paragraph';
import Spacer from '../atoms/utility/Spacer';
import Button from '../atoms/inputs/Button';

const EventsListBlogArtPrize = ({ eventsData }) => {
  const formatDate = (date) => {
    date = new Date(date);
    if (!date) {
      return '';
    }

    const options = {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    };
    return date.toLocaleString('en-US', options);
  };

  if (!eventsData || Object.keys(eventsData)?.length === 0) {
    return <div></div>;
  }

  function setLocation(properties) {
    if (!properties?.street || !properties?.city || !properties?.state)
      return null;
    return `${properties?.street}, ${properties?.city}, ${properties?.state}`;
  }

  return (
    <div className="p-4 text-grey">
      <div className="text-center mb-12 lg:mb-20 max-w-lg mx-auto md:max-w-4xl">
        <h1 className="text-4xl md:text-7xl flex flex-col gap-4 md:gap-6">
          <span className="block">What's going on at</span>
          <span className="block font-bold">ArtPrize?</span>
        </h1>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-1 gap-4">
        {eventsData?.features?.map((data) => {
          const properties = data.properties;
          const formattedStartDate = formatDate(properties.start_time_utc);
          const formattedEndDate = formatDate(properties.end_time_utc);
          const location = setLocation(properties);

          return (
            <div key={i} className="md:flex rounded p-4">
              <div className="md:h-full flex flex-col items-center justify-center">
                <img
                  src={
                    properties.og_image_url || properties.community_image_url
                  }
                  alt={properties.name}
                  className="mb-2 rounded max-w-[320px]"
                />
              </div>
              <div className="flex flex-col py-4 md:p-4 lg:p-12 gap-4">
                <h3 className="text-xl lg:text-4xl mb-2 font-bold">
                  {properties.name}
                </h3>
                <div className="text-base lg:text-xl flex flex-col gap-2 md:gap-4">
                  <p className="mb-2">
                    <span className="font-bold">Date:</span>{' '}
                    {formattedStartDate} {formattedEndDate ? '-' : ''}{' '}
                    {formattedEndDate}
                  </p>

                  {location && (
                    <p className="mb-2">
                      <span className="font-bold">Location: </span> {location}
                    </p>
                  )}
                  <a href={properties.url} target="_blank">
                    <span className="font-bold">Website: </span>
                    <span className="text-highlight">Learn More</span>
                  </a>
                  <p className="mb-2">
                    <span className="font-bold">Info:</span>{' '}
                    {properties.description}
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <Spacer sizeY={1} />
      <div>
        <h3 className="text-3xl md:text-4xl lg:text-5xl text-light-grey font-light lg:leading-[62.5px] mb-4 text-center">
          Your <span className="font-bold">Activities</span>, Your{' '}
          <span className="font-bold">Story</span>
        </h3>
        <Paragraph
          paragraphText={`Share Your Adventures with a Global Community and become a Leader on the flockx Web App today!`}
          className="max-w-xl mx-auto mb-4 md:mb-8 text-grey-100 text-center"
        />
        <div className="mx-auto flex items-center justify-center w-[min-content]">
          <a href="https://app.flockx.io" target="_blank" className="w-full">
            <Button label="Learn More" className="w-[200px]" />
          </a>
        </div>
      </div>
      <Spacer sizeY={1} />
    </div>
  );
};

export default EventsListBlogArtPrize;
