import { useEffect, useState } from 'react';
import {
  extractSources,
  createFeatureCollection,
} from '../helpers/elastic-search';

export default function useFetchArtPrizeActivities() {
  const [featureCollection, setFeatureCollection] = useState({});

  useEffect(() => {
    async function fetchActivities() {
      try {
        const res = await fetch('/.netlify/functions/es-artprize-activities/');
        const data = await res.json();
        const features = extractSources(data);
        setFeatureCollection(createFeatureCollection(features));
      } catch (error) {
        console.log(error.message);
      }
    }

    fetchActivities();
  }, []);

  return { featureCollection };
}
